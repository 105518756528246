// src/composables/useMetaPixel.js
let currentPixelId = null; // 记录当前生效的 Pixel ID

export function useMetaPixel(pixelId, trackPageView = true) {
  // 如果已加载过相同的 Pixel ID，不再重复初始化
  if (currentPixelId === pixelId) return;

  // 1. 首次加载时插入 fbevents.js
  if (!window.fbq) {
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    script.async = true;
    document.head.appendChild(script);

    window.fbq = function() {
      window.fbq.callMethod?.apply(window.fbq, arguments) || window.fbq.queue.push(arguments);
    };
    window.fbq.queue = [];
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
  }

  // 2. 清理旧 Pixel 的队列（避免事件混淆）
  if (currentPixelId) {
    window.fbq.queue = []; // 清空未发送的事件
  }

  // 3. 初始化新 Pixel
  window.fbq('init', pixelId);
  if (trackPageView) {
    window.fbq('track', 'PageView');
  }

  // 4. 更新当前 Pixel ID
  currentPixelId = pixelId;

  // 5. 插入 noscript 回退（需放在 body）
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
  `;
  document.body.appendChild(noscript);
}